import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {ITrainer} from "./Models/itrainer";
import {IStatus} from "./models/istatus";


@Injectable({
  providedIn: 'root'
})

export class TrainersService {
  public trainers: IStatus[];
  private controller: string = 'trainers';

  constructor(private api: ApiService<ITrainer>) {
    this.trainers = null;
  }

  loadIntoCache(): Observable<any> {
    if (this.trainers !== null) {
      return of([]);
    }
    return this.api.get(this.controller).pipe(
      map(statuses => {
        this.trainers = statuses;
        return null;
      })
    );
  }
}
