import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../api/auth.service";
import {Router} from "@angular/router";
import {LoaderService} from "../../api/loader-service";
import {ILoaderState} from "../../api/Models/i-loader-state";
import {Subscription} from "rxjs";

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html'

})
export class BreadcrumbsComponent implements OnInit {

  currentUser: string;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser.userName;
  }

  logout(event: Event): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
