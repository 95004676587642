import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {NlpPageNotFoundComponent} from './page-not-found/nlp-page-not-found.component';
import {StudentListComponent} from './students/student-list.component';
import {BatchesService} from "../api/batches.service";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InstitutesService} from "../api/institutes.service";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from "@angular/common";
import {NlpMessageModule} from "./messages/nlp-message.module";
import {NlpPageStaticPartsModuleModule} from "./page-static-parts/nlp-page-static-parts.module";
import {NlpRoutingModule} from "./app-routing.module";
import {BatchDetailsComponent} from "./batches/batch-details.component";
import {BatchListComponent} from "./batches/batch-list.component";
import {NoteListComponent} from "./notes/note-list.component";
import {BatchesResolver} from "./batches/resolvers/batches-resolver.service";
import {ApiService} from "../api/api.service";
import {StudentComponent} from "./students/student.component";
import {StatusesService} from "../api/statuses.service";
import {StudentsResolver} from "./students/students-resolver.service";
import {StudentsService} from "../api/students.service";
import {CountriesService} from "../api/countries.service";
import {LevelsService} from "../api/levels.service";
import {StudentLevelListComponent} from "./students/student-level-list.component";
import {NotesService} from "../api/notes.service";
import {NotesResolver} from "./notes/notes-resolver.service";
import {AuthService} from "../api/auth.service";
import {AuthGuard} from "../api/auth-guard.service";
import {BatchListResolver} from "./batches/resolvers/batch-list-resolver.service";
import {TrainersService} from "../api/trainers.service";
import {BatchStatusComponent} from "./batches/batch-status.component";
import {UsersService} from "../api/users.service";
import {UsersResolver} from "./users/users-resolver.service";
import {UserListComponent} from "./users/user-list.component";
import {UserDetailsComponent} from "./users/user-details.component";
import {MultiSelectComponent} from "./shared/multi-select.component";
import {InstituteListComponent} from "./institutes/institute-list.component";
import {InstituteDetailsComponent} from "./institutes/institute-details.component";
import {InstitutesResolver} from "./institutes/institutes-resolver.service";
import {OptionListsService} from "../api/option-lists.service";
import {SafePipe} from "../api/pipes/safe.pipe";
import {ConfigurationService} from "../api/configuration.service";
import {LoginComponent} from "./login/login.component";
import {LoaderService} from "../api/loader-service";
import {BreadcrumbsComponent} from "./page-static-parts/breadcrumbs.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NlpPageNotFoundComponent,
    BatchDetailsComponent,
    BatchListComponent,
    BatchStatusComponent,
    NoteListComponent,
    StudentComponent,
    StudentListComponent,
    StudentLevelListComponent,
    UserListComponent,
    UserDetailsComponent,
    MultiSelectComponent,
    InstituteListComponent,
    InstituteDetailsComponent,
    SafePipe
  ],
  imports: [
    NlpPageStaticPartsModuleModule,
    NlpMessageModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NlpRoutingModule
  ],
  providers: [
    AuthService,
    BatchesResolver,
    BatchListResolver,
    StudentsResolver,
    AuthGuard,
    NotesResolver,
    ApiService,
    BatchesService,
    OptionListsService,
    InstitutesService,
    InstitutesResolver,
    StudentsService,
    CountriesService,
    StatusesService,
    NotesService,
    LevelsService,
    TrainersService,
    UsersService,
    UsersResolver,
    ConfigurationService,
    LoaderService
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}
