﻿import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {Injectable} from "@angular/core";
import {of} from "rxjs/internal/observable/of";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {INote} from "../../api/Models/inote";
import {CountriesService} from "../../api/countries.service";
import {LevelsService} from "../../api/levels.service";
import {NotesService} from "../../api/notes.service";


@Injectable()
export class NotesResolver implements Resolve<INote[]> {

  constructor(private notesService: NotesService,
              private router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    let batchID = +route.params['id'];
    if (isNaN(batchID)) {
      this.router.navigate(['/']);
      return of(null);
    }

    return this.notesService.load(batchID);
  }
}
