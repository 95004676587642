import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ILevel} from "../../api/Models/ilevel";
import {LevelsService} from "../../api/levels.service";

@Component({
  selector: 'student-level-list',
  templateUrl: './student-level-list.component.html'
})
export class StudentLevelListComponent implements OnInit {

  @Input() selectedLevels: number[];
  levels: string[];

  constructor(private levelsService: LevelsService) {
    this.levels = []
  }

  ngOnInit(): void {
    // this.allLevels.forEach((level, index) => {
    //   if (this.selectedLevels.indexOf(level.id) > -1) {
    //     this.levels.push(level.title);
    //   }
    // });
  }


  // get allLevels(): ILevel[] {
  //   //return this.levelsService.levels;
  // }

}
