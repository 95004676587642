import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {NlpMessageService} from '../app/messages/nlp-message.service';
import index from '@angular/cli/lib/cli';

@Injectable({
  providedIn: 'root'
})
export class BatchesService {
  public batches: IBatch[];
  private controller: string = 'batches';

  constructor(private api: ApiService<IBatch>,
              private messages: NlpMessageService) {
    this.batches = null;
  }

  loadIntoCache(): Observable<IBatch[]> {
    if (this.batches !== null) {
      return of(this.batches);
    }

    return this.api.get(this.controller).pipe(
      map(batches => {
        this.batches = batches;

        if (this.batches.length > 0) {
          // this.messages.success('Batches have been loaded successfully.');
        }

        return this.batches;
      })
    );
  }

  getCached(id: number): IBatch {
    return this.batches.find(i => {
        return i.id === id;
      }
    );
  }

  update(batch: IBatch): Observable<IBatch> {
    return this.api.put(this.controller, batch.id, batch)
      .pipe(
        map(batch => {
          this.messages.success('Batch has been Updated!');

          let index = this.batches.indexOf(this.getCached(batch.id));
          this.batches.splice(index, 1, batch);

          return batch;
        })
      );
  }
}
