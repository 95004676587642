﻿import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {Injectable} from "@angular/core";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {IInstitute} from "../../api/Models/iinstitute";
import {InstitutesService} from "../../api/institutes.service";
import {ConfigurationService} from "../../api/configuration.service";


@Injectable()
export class InstitutesResolver implements Resolve<IInstitute[]> {

  constructor(
    private institutesService: InstitutesService,
    private configurationService: ConfigurationService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.institutesService.loadIntoCache(),
      this.configurationService.loadIntoCache()
    );
  }
}
