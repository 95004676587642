import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IBatch} from './Models/IBatch';
import {EMPTY} from 'rxjs';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {IStatus} from './Models/istatus';
import {IInstitute} from './Models/iinstitute';
import {IOption} from "./models/ioption";
import {IUser} from "./models/iuser";
import {UserTypes} from "./models/enums/user-types";
import {NlpMessageService} from "../app/messages/nlp-message.service";

@Injectable({
  providedIn: 'root'
})

export class InstitutesService {
  public institutes: IInstitute[];
  private controller: string = 'institutes';

  constructor(private api: ApiService<IInstitute>) {
    this.institutes = null;
  }


  loadIntoCache(): Observable<any> {
    if (this.institutes !== null) {
      return of([]);
    }

    return this.api.get(this.controller).pipe(
      map(items => {
        this.institutes = items;
        return null;
      })
    );
  }

  getCached(id: number): IInstitute {
    return this.institutes.find(i => {
        return i.id === id;
      }
    );
  }

  save(institute: IInstitute): Observable<IInstitute> {
    if (institute.id > 0) {
      return this.api.put(this.controller, institute.id, institute)
        .pipe(
          map(() => {
            let index = this.institutes.indexOf(this.getCached(institute.id));
            this.institutes.splice(index, 1, institute);

            return institute;
          })
        );
    }
    else {
      return this.api.post(this.controller, institute)
        .pipe(
          map((res) => {
            this.institutes.push(res);
            return res;
          })
        );
    }
  }

  static create(): IInstitute {
    return {
      id: 0,
      title: '',
      titleSize: 80,
      curvingAngle: 1900
    } as IInstitute;
  }
}
