import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ILevel} from "../../api/Models/ilevel";
import {LevelsService} from "../../api/levels.service";
import {Statuses} from "../../api/models/enums/statuses";

@Component({
  selector: 'batch-status',
  templateUrl: './batch-status.component.html'
})
export class BatchStatusComponent {

  @Input() statusDescription: string;
  @Input() status: Statuses;

  get className(): string{
    switch (this.status)
    {
      case Statuses.Completed:
        return 'label-success';
      case Statuses.Archived:
        return 'label-warning';
      default:
        return 'label-info'
    }
  }

  constructor() {
  }
}
