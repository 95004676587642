import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlp-page-not-found',
  templateUrl: './nlp-page-not-found.component.html',
  styleUrls: ['./nlp-page-not-found.component.css']
})
export class NlpPageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
