import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from "rxjs/operators";
import {throwError} from "rxjs";
import {AuthService} from "./auth.service";
import {LoaderService} from "./loader-service";


@Injectable({
  providedIn: 'root'
})
export class ApiService<T> {

  private _url = '/api/';
  private _http: HttpClient;
  private _authService: AuthService;

  constructor(http: HttpClient,
              authService: AuthService,
              private loader: LoaderService) {
    this._http = http;
    this._authService = authService;
  }

  getUrl(controller: string, params?: string): string {
    if (!!params) {
      return this._url + controller + "?" + params;
    }
    return this._url + controller + '/';
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + this._authService.token
    });

    return headers;
  }


  public getOne(controller: string): Observable<T> {
    this.loader.show();
    return this._http.get<T>(this.getUrl(controller), {headers: this.getHeaders()})
      .pipe(
        map(info => {
          this.loader.hide();
          return info;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }

  public get(controller: string): Observable<T[]> {
    this.loader.show();
    return this._http.get<T[]>(this.getUrl(controller), {headers: this.getHeaders()})
      .pipe(
        map(info => {
          this.loader.hide();
          return info;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }

  public getWithParams(controller: string, params: string): Observable<T[]> {
    this.loader.show();
    return this._http.get<T[]>(this.getUrl(controller, params), {headers: this.getHeaders()})
      .pipe(
        map(info => {
          this.loader.hide();
          return info;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }

  public post(controller: string, item: T): Observable<T> {
    this.loader.show();
    return this._http.post<T>(this.getUrl(controller), item, {headers: this.getHeaders()})
      .pipe(
        map(info => {
          this.loader.hide();
          return info;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }

  public put(controller: string, id: number, item: T): Observable<T> {
    this.loader.show();
    return this._http.put<T>(this.getUrl(controller) + id, item, {headers: this.getHeaders()})
      .pipe(
        map(info => {
          this.loader.hide();
          return info;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }

  public del(controller: string, id: number): Observable<string> {
    this.loader.show();
    return this._http.delete<string>(this.getUrl(controller) + id, {headers: this.getHeaders()})
      .pipe(
        map(info => {
          this.loader.hide();
          return info;
        }), catchError((err) => {
          this.loader.hide();
          return throwError(err);
        })
      );
  }
}
