import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ApiService} from './api.service';
import {map} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {NlpMessageService} from "../app/messages/nlp-message.service";
import {IStudent} from "./Models/istudent";

@Injectable({
  providedIn: 'root'
})

export class StudentsService {
  public batchId: number;
  public students: IStudent[];
  private controller: string = 'students';

  constructor(private api: ApiService<IStudent>,
              private messages: NlpMessageService) {
    this.students = null;
  }

  private setUrl(batchId: number) {
    this.controller = 'batches/' + batchId + '/students';
  }

  load(batchId: number): Observable<IStudent[]> {
    this.batchId = batchId;
    this.setUrl(batchId);


    if (batchId === 0) {
      this.students = null;
      return of(this.students);
    }

    return this.api.get(this.controller).pipe(
      map(students => {
        this.students = students;

        if (this.students.length > 0) {
          //     this.messages.success('Students have been loaded successfully.');
        }

        return this.students;
      })
    );
  }

  getCached(id: number): IStudent {
    return this.students.find(i => {
        return i.id === id;
      }
    );
  }
}
