import {Component, Input, OnInit} from '@angular/core';
import {IBatch} from '../../api/Models/IBatch';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin} from 'rxjs/index';
import {BatchesService} from '../../api/batches.service';
import {IStatus} from "../../api/Models/istatus";
import {StatusesService} from "../../api/statuses.service";
import {filterQueryId} from "@angular/core/src/view/util";
import {IInstitute} from "../../api/Models/iinstitute";
import {consoleTestResultHandler} from "tslint/lib/test";
import {ITrainer} from "../../api/Models/itrainer";
import {TrainersService} from "../../api/trainers.service";
import {OptionListsService} from "../../api/option-lists.service";
import {IOption} from "../../api/models/ioption";

@Component({
  templateUrl: './batch-list.component.html'
})
export class BatchListComponent implements OnInit {

  selectedBatchId: number;
  batches: IBatch[];
  statuses: IStatus[];
  trainers: ITrainer[];
  institutes: IOption[];
  private _filterTrainer: number;
  private _filterStatus: number;
  private _filterInstitute: number;
  private _filter: string;

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    this.performFilter();
  }


  get filterTrainer(): number {
    return this._filterTrainer;
  }

  set filterTrainer(value: number) {
    this._filterTrainer = +value;
    this.performFilter();
  }


  get filterStatus(): number {
    return this._filterStatus;
  }

  set filterStatus(value: number) {
    this._filterStatus = +value;
    this.performFilter();
  }

  get filterInstitute(): number {
    return this._filterInstitute;
  }

  set filterInstitute(value: number) {
    this._filterInstitute = +value;
    this.performFilter();
  }


  private _batchesService: BatchesService;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private statusesService: StatusesService,
              private optionListsService: OptionListsService,
              private trainersService: TrainersService,
              batchesService: BatchesService) {
    this._batchesService = batchesService;
  }

  newBatch(event): void {
    this.router.navigate(['batches', 0]);
  }

  openDetails(id, $event): void {
    this.router.navigate(['batches', id], {relativeTo: this.route.parent});
  }

  ngOnInit() {
    this.batches = this._batchesService.batches;

    this.route.params.subscribe(params => {
      this.selectedBatchId = +params['id'];
    });

    this.loadFilters();
  }

  private loadFilters() {
    this.statuses = this.statusesService.statuses;
    if (this.statuses
      .filter((t) => t.id == 1).length > 0) {
      this._filterStatus = 1;
    }
    else {
      this._filterStatus = 0;
    }

    this.trainers = this.trainersService.trainers;
    this._filterTrainer = 0;

    this.institutes = this.optionListsService.institutes;
    this._filterInstitute = 0;

    this._filter = "";

    this.performFilter();
  }

  private performFilter() {
    this.batches = this._batchesService.batches
      .filter((t) => (this.filterTrainer === 0 || t.trainerId === this.filterTrainer)
        && (this.filterInstitute === 0 || t.instituteId === this.filterInstitute)
        && (this.filterStatus === 0 || t.status === this.filterStatus)
        && (this._filter === "" || t.title.toLowerCase().indexOf(this._filter.toLowerCase()) > -1)
      );
  }

}
