﻿import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {Injectable} from "@angular/core";
import {of} from "rxjs/internal/observable/of";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {IUser} from "../../api/Models/iuser";
import {UsersService} from "../../api/users.service";
import {CountriesService} from "../../api/countries.service";
import {LevelsService} from "../../api/levels.service";
import {OptionListsService} from "../../api/option-lists.service";


@Injectable()
export class UsersResolver implements Resolve<IUser[]> {

  constructor(private usersService: UsersService,
              private levelsService: LevelsService,
              private optionListsService: OptionListsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.usersService.loadIntoCache(),
      this.levelsService.loadIntoCache(),
      this.optionListsService.loadInstitutesIntoCache()
    );
  }
}
