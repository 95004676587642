import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'nlp-footer',
  templateUrl: './nlp-footer.component.html',
  styleUrls: ['./nlp-footer.component.css']
})
export class NlpFooterComponent implements OnInit {
  public year: number;


  constructor() {
  }

  ngOnInit() {
    this.year = 2018;
  }

}
