import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IStatus} from "../../api/Models/istatus";
import {IBatch} from "../../api/Models/ibatch";
import {IStudent} from "../../api/Models/istudent";
import {ICountry} from "../../api/Models/icountry";
import {CountriesService} from "../../api/countries.service";
import {StudentsService} from "../../api/students.service";

@Component({
  selector: 'students',
  templateUrl: './student-list.component.html'
})
export class StudentListComponent implements OnInit {

  students: IStudent[];
  private _filter: string;

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    this.performFilter();
  }


  constructor(private router: Router,
              private studentsServices: StudentsService,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.loadFilters();
    this.students = this.studentsServices.students;
  }

  private loadFilters() {
    this._filter = "";
  }

  private performFilter() {
    this.students = this.studentsServices.students
      .filter((t) =>
        this._filter === "" ||
        t.firstName.indexOf(this._filter) > -1 ||
        t.lastName.indexOf(this._filter) > -1
      );
  }
}
