﻿import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {IBatch} from "../../../api/Models/IBatch";
import {Observable} from "rxjs/internal/Observable";
import {catchError, map} from "rxjs/operators";
import {BatchesService} from "../../../api/batches.service";
import {Injectable} from "@angular/core";
import {of} from "rxjs/internal/observable/of";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {StatusesService} from "../../../api/statuses.service";
import {OptionListsService} from "../../../api/option-lists.service";
import {ConfigurationService} from "../../../api/configuration.service";
import {StudentsService} from "../../../api/students.service";


@Injectable()
export class BatchesResolver implements Resolve<IBatch[]> {

  constructor(
    private studentsService: StudentsService,
    private batchesService: BatchesService,
    private statusesService: StatusesService,
    private optionListsService: OptionListsService,
    private configurationService: ConfigurationService,
    private router: Router
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let id = +route.params['id'];
    if (isNaN(id)) {
      this.router.navigate(['/']);
      return of(null);
    }

    return forkJoin(
      this.batchesService.loadIntoCache()
        .pipe(
          map(done => {
            if (id === 0 || !!this.batchesService.getCached(id)) {
              return null;
            }
            this.router.navigate(['/']);
            return null;
          })),
      this.statusesService.loadIntoCache(),
      this.optionListsService.loadInstitutesIntoCache(),
      this.configurationService.loadIntoCache(),
      this.studentsService.load(id),
    );
  }
}
