import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {IInstitute} from "../../api/models/iinstitute";
import {InstitutesService} from "../../api/institutes.service";
import {ConfigurationService} from "../../api/configuration.service";


@Component({
  templateUrl: './institute-details.component.html'
})
export class InstituteDetailsComponent implements OnInit {
  institute: IInstitute;
  instituteForm: FormGroup;
  iframesrc: string = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private institutesService: InstitutesService,
              private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.initInstitute(+params['id']);
    });


  }

  back($event): void {
    this.router.navigate(['institutes']);
  }

  private save($event): void {
    const p = {...this.institute, ...this.instituteForm.value};

    this.institutesService.save(p)
      .subscribe(
        batch => {
          this.router.navigate(['institutes']);
        }
      );
  }

  private reviewCertificate($event): void {
    const certModel = this.instituteForm.value;
    this.iframesrc = this.configurationService.configuration.certificateUrl + '/reports/example?title=' + certModel.title + '&size=' + certModel.titleSize + '&curvingAngle=' + certModel.curvingAngle;
  }

  private initInstitute(id: number) {
    if (id === 0) {
      this.institute = InstitutesService.create();
    } else {
      this.institute = this.institutesService.getCached(id);
    }

    this.instituteForm = this.fb.group({
      title: this.institute.title,
      titleSize: this.institute.titleSize,
      curvingAngle: this.institute.curvingAngle
    })

  }
}
