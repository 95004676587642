import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {AuthService} from "./auth.service";
import {ILoaderState} from "./Models/i-loader-state";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderCount: number = 0;
  private loaderSubject = new Subject<ILoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {
  }

  show() {
    this.loaderCount++;
    if (this.loaderCount > 0) {
      this.loaderSubject.next(<ILoaderState>{show: true});
    }
  }

  hide() {
    this.loaderCount--;
    if (this.loaderCount < 1) {
      {
        this.loaderSubject.next(<ILoaderState>{show: false});
      }
    }
  }
}
