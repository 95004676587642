import {Component, OnInit} from '@angular/core';
import {IBatch} from "../../api/Models/IBatch";
import {BatchesService} from "../../api/batches.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Statuses} from "../../api/models/enums/statuses";
import {OptionListsService} from "../../api/option-lists.service";
import {ConfigurationService} from "../../api/configuration.service";
import {StudentsService} from "../../api/students.service";
import {StatusesService} from "../../api/statuses.service";

@Component({
  templateUrl: './batch-details.component.html'
})
export class BatchDetailsComponent implements OnInit {

  batch: IBatch;
  selectedTab: string;
  showLoadCertificates: boolean = false;
  showComplete: boolean = false;
  showCancel: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private batchesService: BatchesService,
              private studentsService: StudentsService,
              private optionListsService: OptionListsService,
              private statusesService: StatusesService,
              private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadBatch(+params['id']);
    });

    this.selectedTab = 'students';
  }

  back($event): void {
    this.router.navigate(['batches']);
  }

  completeBatch($event): void {
    this.batch.status = Statuses.Completed;
    this.statusesService.statuses = null;
    this.save();
  }

  cancelBatch($event): void {
    this.batch.status = Statuses.Returned;
    this.statusesService.statuses = null;
    this.save();
  }

  private save(): void {
    this.batchesService.update(this.batch)
      .subscribe(
        batch => {
          this.setbuttonVisibility();
          //Load The certificates in a new tab
          setTimeout(() => {
            this.loadCertificates(null);
          }, 400);
        }
      );
  }

  loadCertificates($event): void {
    if (this.batch.status === Statuses.Completed) {
      window.open(this.configurationService.configuration.certificateUrl + '/reports/' + this.batch.id, '_blank');
    }
  }

  private loadBatch(batchId: number) {
    this.batch = this.batchesService.getCached(batchId);
    this.setbuttonVisibility();
  }

  setbuttonVisibility() {
    this.showLoadCertificates = this.batch.status === Statuses.Completed;

    //TODO: at least one entry to complete
    this.showComplete = this.batch.status === Statuses.Sent
      && this.studentsService.students.length > 0;

    //TODO even completed could be canceled?
    this.showCancel = this.batch.status === Statuses.Sent;
  }

  selectTab(tab, $event): void {
    this.selectedTab = tab;
    $event.preventDefault();
  }
}
