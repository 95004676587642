import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ApiService} from './api.service';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {ICountry} from './Models/icountry';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  public countries: ICountry[];
  private controller: string = 'countries';

  constructor(private api: ApiService<ICountry>) {
    this.countries= null;
  }


  loadIntoCache(): Observable<any> {
    if (this.countries !== null)
    {
      return of([]);
    }
    return this.api.get(this.controller).pipe(
      map(items => {
        this.countries = items;
        return null;
      })
    );
  }
}
