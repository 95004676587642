﻿import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {Injectable} from "@angular/core";
import {of} from "rxjs/internal/observable/of";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {IStudent} from "../../api/Models/istudent";
import {StudentsService} from "../../api/students.service";
import {CountriesService} from "../../api/countries.service";
import {LevelsService} from "../../api/levels.service";


@Injectable()
export class StudentsResolver implements Resolve<IStudent[]> {

  constructor(private studentsService: StudentsService,
              private countriesService: CountriesService,
              private levelsService: LevelsService,
              private router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    let batchID = +route.params['id'];
    if (isNaN(batchID)) {
      this.router.navigate(['/']);
      return of(null);
    }

    return forkJoin(
      this.studentsService.load(batchID),
      this.countriesService.loadIntoCache(),
      this.levelsService.loadIntoCache()
    );
  }
}
