import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  templateUrl: './student.component.html'
})
export class StudentComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.parent.params.subscribe(
      params => {
        let selectedBatchId = +params['id'];
        if (selectedBatchId===0) {
          this.router.navigate(['edit'], {relativeTo: this.route.parent});
        }
      }
    )
  }

}
