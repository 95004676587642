import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {NlpPageNotFoundComponent} from "./page-not-found/nlp-page-not-found.component";
import {BatchDetailsComponent} from "./batches/batch-details.component";
import {StudentsResolver} from "./students/students-resolver.service";
import {NotesResolver} from "./notes/notes-resolver.service";
import {AuthGuard} from "../api/auth-guard.service";
import {BatchListComponent} from "./batches/batch-list.component";
import {BatchListResolver} from "./batches/resolvers/batch-list-resolver.service";
import {UserListComponent} from "./users/user-list.component";
import {UsersResolver} from "./users/users-resolver.service";
import {UserDetailsComponent} from "./users/user-details.component";
import {InstituteListComponent} from "./institutes/institute-list.component";
import {InstitutesResolver} from "./institutes/institutes-resolver.service";
import {InstituteDetailsComponent} from "./institutes/institute-details.component";
import {LoginComponent} from "./login/login.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
      {
        path: 'users',
        component: UserListComponent,
        resolve: {
          users: UsersResolver
        },
        canActivate: [AuthGuard]
      },
     {
        path: 'institutes',
        component: InstituteListComponent,
        resolve: {
          institute: InstitutesResolver
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'institutes/:id',
        component: InstituteDetailsComponent,
        resolve: {
          institute: InstitutesResolver
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'users/:id',
        component: UserDetailsComponent,
        resolve: {
          users: UsersResolver
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'batches',
        component: BatchListComponent,
        resolve: {
          batches: BatchListResolver
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'batches/:id',
        component: BatchDetailsComponent,
        resolve: {
          batches: BatchListResolver,
          students: StudentsResolver,
          notes: NotesResolver
        },
        canActivate: [AuthGuard],
      },
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: '**', component: NlpPageNotFoundComponent},

    ])
  ],
  exports: [RouterModule]
})

export class NlpRoutingModule {
}
