import { NgModule } from '@angular/core';
import { NlpMessageService } from './nlp-message.service';
import {ToastrModule} from "ngx-toastr";

@NgModule({
    imports: [
      ToastrModule.forRoot(),
    ],
    declarations: [
    ],
    providers: [
        NlpMessageService
    ]
})
export class NlpMessageModule{}
