﻿
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanLoad, Router, Route } from '@angular/router';
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";


@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService,
              private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLoggedIn(state.url);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkLoggedIn(route.path);
  }

  checkLoggedIn(url: string): Observable<boolean> {

    return this.authService.isLoggedIn().pipe(
      map(res => {
        if (!res) {
          this.authService.redirectUrl = url;
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }));

  }
}
