﻿import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {IBatch} from "../../../api/Models/IBatch";
import {Observable} from "rxjs/internal/Observable";
import {catchError, map} from "rxjs/operators";
import {BatchesService} from "../../../api/batches.service";
import {Injectable} from "@angular/core";
import {of} from "rxjs/internal/observable/of";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {StatusesService} from "../../../api/statuses.service";
import {TrainersService} from "../../../api/trainers.service";
import {OptionListsService} from "../../../api/option-lists.service";
import {ConfigurationService} from "../../../api/configuration.service";


@Injectable()
export class BatchListResolver implements Resolve<IBatch[]> {

  constructor(private batchesService: BatchesService,
              private statusesService: StatusesService,
              private trainersService: TrainersService,
              private optionListsService: OptionListsService,
              private configurationService: ConfigurationService
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.batchesService.loadIntoCache(),
      this.statusesService.loadIntoCache(),
      this.trainersService.loadIntoCache(),
      this.optionListsService.loadInstitutesIntoCache(),
      this.configurationService.loadIntoCache()

    );
  }
}
