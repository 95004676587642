import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IStatus} from "../../api/Models/istatus";
import {IBatch} from "../../api/Models/ibatch";
import {IInstitute} from "../../api/Models/iinstitute";
import {ICountry} from "../../api/Models/icountry";
import {CountriesService} from "../../api/countries.service";
import {InstitutesService} from "../../api/institutes.service";
import {IOption} from "../../api/models/ioption";

@Component({
  templateUrl: './institute-list.component.html'
})
export class InstituteListComponent implements OnInit {

  institutes: IInstitute[];
  private _filter: string;
  private _filterType: number;

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    this.performFilter();
  }

  get filterType(): number {
    return this._filterType;
  }

  set filterType(value: number) {
    this._filterType = +value;
    this.performFilter();
  }

  constructor(private router: Router,
              private institutesService: InstitutesService,
  ) {
  }

  ngOnInit() {
    this.loadFilters();
  }

  private loadFilters() {
    this._filter = "";
    this._filterType = 1;

    this.performFilter();
  }

  private performFilter() {
    this.institutes = this.institutesService.institutes
      .filter((t) =>
        this._filter === "" ||
        t.title.toLowerCase().indexOf(this._filter.toLowerCase()) > -1
      );
  }


  newInstitute(event): void {
    this.router.navigate(['institutes', 0]);
  }

  openDetails(id, $event): void {
    this.router.navigate(['institutes', id]);
  }


}
