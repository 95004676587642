import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ILevel} from "../../api/Models/ilevel";
import {LevelsService} from "../../api/levels.service";
import {IOption} from "../../api/models/ioption";

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent {

  @Input() title: string;
  @Input() selectedOptions: number[];
  @Input() options: IOption[];
  @Output() toggleOption: EventEmitter<IOption> = new EventEmitter();

  constructor() {
  }

  toggle(item: IOption, $event) {
    this.toggleOption.emit(item);
  }
}
