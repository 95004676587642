import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../api/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IAuth} from "../../api/Models/iauth";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  loginForm: FormGroup;
  errorMessage: string;
  auth: IAuth;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.loginForm = this.fb.group(
      {
        userName: ['', Validators.required],
        password: ['', Validators.required]
      });
  }

  login(): void {

    if (!this.loginForm.valid || this.loading) {
      return;
    }

    this.errorMessage = '';
    this.loading = true;
    const authValue = {...this.auth, ...this.loginForm.value};
    this.authService.login(authValue)
      .subscribe(
        res => {
          this.loading = false;
          this.router.navigate(['/batches']);
        },
        error => {
          this.errorMessage = 'Could not Login';
          this.loading = false;
        }
      );
  }
}
