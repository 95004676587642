import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../api/auth.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {LoaderService} from "../../api/loader-service";
import {ILoaderState} from "../../api/models/i-loader-state";

@Component({
  selector: 'nlp-header',
  templateUrl: './nlp-header.component.html',
  styleUrls: ['./nlp-header.component.css']
})
export class NlpHeaderComponent implements OnInit {

  userName: string;

  showLoader: boolean = false;

  private subscription: Subscription;
  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: ILoaderState) => {
        this.showLoader = state.show;
      });
  }

  logout(event: Event): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  isLoggedIn()
  {
    return this.authService.currentUser != null;
  }
}
