import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StudentsService} from "../../api/students.service";
import {CountriesService} from "../../api/countries.service";
import {BatchesService} from "../../api/batches.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IUser} from "../../api/models/iuser";
import {UsersService} from "../../api/users.service";
import {UserTypes} from "../../api/models/enums/user-types";
import {Statuses} from "../../api/models/enums/statuses";
import {IOption} from "../../api/models/ioption";
import {LevelsService} from "../../api/levels.service";
import {ILevel} from "../../../../../../Trainer/Trainer.UI/client-app/src/api/Models/ilevel";
import {OptionListsService} from "../../api/option-lists.service";


@Component({
  templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit {

  user: IUser;
  userForm: FormGroup;
  levels: IOption[];
  institutes: IOption[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private usersService: UsersService,
              private levelsService: LevelsService,
              private optionListsService: OptionListsService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      title: [''],
      userName: ['',
        [Validators.required,
          Validators.minLength(4)]],
      password: ['',
        [Validators.required]],
      firstName: [''],
      lastName: [''],
      email: ['',
        [Validators.required,
          Validators.email]],
      phone: [''],
      userType: ['']
    })
    ;

    this.route.params.subscribe(params => {
      const id = +params['id'];

      if (id === 0) {
        this.user = UsersService.create();
      } else {
        this.user = this.usersService.getCached(id);
      }

      this.initUser();
    });
    this.levels = this.levelsService.levels;
    this.institutes = this.optionListsService.institutes;
  }

  back($event): void {
    this.router.navigate(['users']);
  }

  private save($event): void {
    const p = {...this.user, ...this.userForm.value};

    this.usersService.save(p)
      .subscribe(
        batch => {
          this.router.navigate(['users']);
        }
      );
  }


  toggleLevel(item: IOption, $event) {
    let index = this.user.levels.indexOf(item.id);
    if (index === -1) {
      this.user.levels.splice(0, 0, item.id);
    }
    else {
      this.user.levels.splice(index, 1);
    }
    this.userForm.markAsDirty();

  }

  toggleInstitute(item: IOption, $event) {
    let index = this.user.institutes.indexOf(item.id);
    if (index === -1) {
      this.user.institutes.splice(0, 0, item.id);
    }
    else {
      this.user.institutes.splice(index, 1);
    }

    this.userForm.markAsDirty();
  }

  private initUser() {
    this.userForm.setValue({
      title: this.user.title,
      userName: this.user.userName,
      password: this.user.password,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      phone: this.user.phone,
      userType: this.user.userType
    });
  }
}
