import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {IUser} from './Models/IUser';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {UserTypes} from "./models/enums/user-types";

@Injectable({
  providedIn: 'root'
})


export class UsersService {
  public users: IUser[];
  private controller: string = 'users';

  constructor(private api: ApiService<IUser>) {
    this.users = null;
  }

  loadIntoCache(): Observable<IUser[]> {
    if (this.users !== null) {
      return of(this.users);
    }

    return this.api.get(this.controller).pipe(
      map(users => {
        this.users = users;

        if (this.users.length > 0) {
          // this.messages.success('Users have been loaded successfully.');
        }

        return this.users;
      })
    );
  }

  getCached(id: number): IUser {
    return this.users.find(i => {
        return i.id === id;
      }
    );
  }

  save(user: IUser): Observable<IUser> {
    if (user.id > 0) {
      return this.api.put(this.controller, user.id, user)
        .pipe(
          map(() => {
        //    this.messages.success('User has been Updated!');
            let index = this.users.indexOf(this.getCached(user.id));
            this.users.splice(index, 1, user);

            return user;
          })
        );
    }
    else {
      return this.api.post(this.controller, user)
        .pipe(
          map((res) => {
        //    this.messages.success('User has been added and notification has been sent!');
            this.users.push(res);

            return res;
          })
        );
    }
  }


  static create(): IUser {
    return {
      id: 0,
      title: '',
      userName: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userType: UserTypes.Trainer,
      institutes: [],
      levels: []
    } as IUser;
  }
}
