import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IStatus} from "../../api/Models/istatus";
import {IBatch} from "../../api/Models/ibatch";
import {IUser} from "../../api/Models/iuser";
import {ICountry} from "../../api/Models/icountry";
import {CountriesService} from "../../api/countries.service";
import {UsersService} from "../../api/users.service";

@Component({
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  users: IUser[];
  private _filter: string;
  private _filterType: number;

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    this.performFilter();
  }

  get filterType(): number {
    return this._filterType;
  }

  set filterType(value: number) {
    this._filterType = +value;
    this.performFilter();
  }

  constructor(private router: Router,
              private usersServices: UsersService,
  ) {
  }

  ngOnInit() {
    this.loadFilters();
  }

  private loadFilters() {
    this._filter = "";
    this._filterType = 1;

    this.performFilter();
  }

  private performFilter() {
    this.users = this.usersServices.users
      .filter((t) =>
        (this._filter === "" ||
          t.title.indexOf(this._filter) > -1 ||
          t.firstName.indexOf(this._filter) > -1 ||
          t.lastName.indexOf(this._filter) > -1 ||
          t.email.indexOf(this._filter) > -1 ||
          t.userName.indexOf(this._filter) > -1 ||
          t.phone.indexOf(this._filter) > -1
        ) &&
        t.userType == this.filterType
      );
  }


  newUser(event): void {
    this.router.navigate(['users', 0]);
  }

  openDetails(id, $event): void {
    this.router.navigate(['users', id]);
  }


}
